<template>
  <ul class="date-filter">
    <li v-for="[keyValue, filter] in filters" :key="keyValue" @click="$emit('filter', filter)"
        :class="{active: JSON.stringify(active) === JSON.stringify(filter)}" >
      {{ keyValue }}
    </li>
  </ul>
</template>

<style scoped>
  .date-filter {
    @apply flex flex-row justify-center items-center gap-2 sm:gap-4;
  }

  .date-filter li {
    @apply font-black text-green-200 cursor-pointer text-sm sm:text-base px-2 sm:px-4 py-1 sm:py-2 transition duration-500 ease-in-out hover:text-white hover:bg-green-100 rounded-lg;

    &.active {
      @apply text-white bg-green-100 ;
    }
  }
</style>

<script>
export default {
  props: {
    active: Object,
    filters: Map
  },
  emits: ['filter'],
}
</script>
