<template>

  <div v-if="currentIndex">
    <Teleport to="#strong-wool-index-number">
      {{ currentIndex.price }}
    </Teleport>
    <Teleport to="#strong-wool-index-date">
      {{ currentIndex.date }}
    </Teleport>
    <Teleport to="#strong-wool-index-sale">
      {{ currentIndex.sale_numbers.join(", ") }}
    </Teleport>
    <Teleport to="#strong-wool-index-diff">
      {{ currentIndex.difference.days }}-day Change
    </Teleport>
    <Teleport to="#strong-wool-index-diff-points">
      <span class="flex-grow number" :class="currentIndex.difference.percentage >= 0 ? 'positive' : 'negative'"
      >{{ currentIndex.difference.value }} ({{ currentIndex.difference.percentage }}%)</span>
    </Teleport>


    <Teleport to="#strong-wool-index-sold">
      {{ currentIndex.bales?.percentage_sold }}%
    </Teleport>
    <Teleport to="#percentage-sold-tooltip">
      Percentage of bales sold in the current auction
<!--      {{ currentIndex.bales?.sold }} / {{ currentIndex.bales?.total }}-->
    </Teleport>
  </div>

</template>

<script>
import {ref, onMounted} from 'vue';
import axios from 'axios';

export default {

  setup() {
    const currentIndex = ref(null)

    // get data from api
    const getData = async () => {
      currentIndex.value = await axios.get('/strong-wool-index/current')
          .then((response) => {
            return response.data
          })
    }

    // call getData on mount
    onMounted(async () => {
      await getData()
    })

    return {
      currentIndex
    }

  }
}
</script>
