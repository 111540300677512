<template>
  <Line :data="chartData" :options="chartOptions"/>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import {Line} from 'vue-chartjs'
import {externalTooltipHandler} from './chartConfig'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export default {
  components: {
    Line
  },
  props: {
    labels: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    chartData() {

      return {
        labels: this.labels,
        datasets: [
          {
            label: 'Cents per kg',
            data: this.data,
            fill: true,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(0, 0, 1736, 344);

              gradient.addColorStop(0, 'green');
              gradient.addColorStop(1, 'white');

              return gradient;
            },
            borderColor: '#188145',
            pointStyle: true,
            pointBackgroundColor: '#188145',
            pointBorderColor: '#188145',
            borderWidth: 2,
            radius: this.labels.length > 30 ? 0 : 4,
          }
        ]
      }
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: externalTooltipHandler
          }
        },
        interaction: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: 'Date'
            }
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Cents per kg'
            },
            suggestedMin: 0,
            suggestedMax: this.data.length > 0 ? Math.max(...this.data)+20 : 0
          }
        }
      }
    }
  }

}
</script>
