<template>

  <Filters :active="active" :filters="filters" @filter="handleClick"/>
  <div class="pt-section-small h-[400px]" v-if="chartData">
    <Chart :labels="chartLabels" :data="chartData"/>
  </div>
  <div class="flex items-end gap-1" v-if="logo">
    <span class="text-xs text-grey-600">Powered by</span>
    <a href="https://www.fusca.co.nz" style="text-decoration: none;" target="_blank" title="Go to Fusca">
      <img src="../img/logo-black.png" alt="Fusca" class="w-[70px]" width="70"/>
    </a>
  </div>

</template>

<script>
import Filters from "./components/Filters";
import Chart from "./components/Chart";
import {ref, onMounted} from 'vue';
import axios from 'axios';

export default {
  components: {
    Filters,
    Chart
  },
  props: {
    logo: {
      default: false
    }
  },
  setup() {
    const active = ref({unit: "years", value: 10})
    const filters = new Map([
      ['1M', {unit: "months", value: 1}],
      ['3M', {unit: "months", value: 3}],
      ['6M', {unit: "months", value: 6}],
      ['9M', {unit: "months", value: 9}],
      ['1Y', {unit: "years", value: 1}],
      ['3Y', {unit: "years", value: 3}],
      ['10Y', {unit: "years", value: 10}],
    ])
    const chartLabels = ref(null)
    const chartData = ref(null)

    const handleClick = (e) => {
      active.value = e
      getData();
    }

    // get data from api
    const getData = async () => {
      await axios.post('/strong-wool-index/historic',
          {
            unit: active.value.unit,
            value: active.value.value
          }
      ).then((response) => {
        // get the keys from the response and set them as the chart labels
        chartLabels.value = Object.keys(response.data)
        // get the values from the response and set them as the chart data
        chartData.value = Object.values(response.data)
      })
    }

    // call getData on mount
    onMounted(async () => {
      await getData()
    })

    return {
      active,
      filters,
      chartLabels,
      chartData,
      handleClick
    }

  }
}
</script>
