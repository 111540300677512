import StrongWoolIndexChart from "../vue/StrongWoolIndexChart.vue";
import StrongWoolIndexPrice from "../vue/StrongWoolIndexPrice.vue";
import * as Vue from 'vue';
import axios from "axios";

// Intiate Vuejs instance if we find #appVue (just in strong wool index page)

window.axios = axios;
window.axios.defaults.baseURL = process.env.MIX_SHOP_API_URL;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
window.axios.defaults.headers.common['Authorization'] = `Bearer ${process.env.MIX_VUE_APP_SANCTUM}`;
window.axios.defaults.headers.common['Accept'] = 'application/json';

const strongWoolIndexPrice = document.getElementById("strongWoolIndexPrice");
if (strongWoolIndexPrice) {
    Vue.createApp({
        components: {
            StrongWoolIndexPrice
        }
    }).mount("#strongWoolIndexPrice");
}

const strongWoolIndexChart = document.getElementById("strongWoolIndexChart");
if (strongWoolIndexChart) {
    Vue.createApp({
        components: {
            StrongWoolIndexChart,
        }
    }).mount("#strongWoolIndexChart");
}